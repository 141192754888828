import { useTextStore } from '@/stores/text'
import alert from '@/utils/alert'

const handleErrorWith = function (messages, error) {
  const textStore = useTextStore()
  const errors = textStore.current.errors
  let message = errors.COMM
  let type = 'error'
  let ignore = false
  let callback = null
  let logout = false

  if (messages) {
    const processResult = processResponse(errors, messages, error)

    message = processResult.message
    type = processResult.type
    ignore = processResult.ignore
    callback = processResult.callback
    logout = processResult.logout
  }

  if (!ignore) {
    alert.message(message, type)
  }

  if (logout) {
    setTimeout(() => { window.location.reload() }, 1000)
  }

  if (callback) {
    callback(error)
  }
}

const processResponse = function (errors, messages, error) {
  const response = {
    message: errors.COMM,
    type: 'error',
    ignore: false,
    callback: null,
    logout: false
  }

  if (error.response) {
    response.logout = error.response.status === 401

    response.message = errors.PROCESS

    if (!error.response.data) {
      response.message = errors.COMM
    }

    if (response.logout) {
      response.message = errors.AUTH
    }

    if (error.response.status === 403) {
      response.message = errors.PERMISSION
    }

    if (messages.default) {
      processResponseWithConfig(messages, error, 'default', response)
    }

    if (messages[error.response.status]) {
      processResponseWithConfig(messages, error, error.response.status, response)
    }
  }

  return response
}

const processResponseWithConfig = function (messages, error, status, response) {
  if (messages[status]) {
    if (messages[status].message) {
      response.message = messages[status].message
    }
    if (messages[status].type) {
      response.type = messages[status].type
    }
    if (messages[status].ignore) {
      response.ignore = true
    }
    if (messages[status].callback) {
      response.callback = messages[status].callback
    }
    if (error.response.data) {
      processResponseData(messages, error, status, response)
    }
  }
}

const processResponseData = function (messages, error, status, response) {
  if (messages?.[status]?.[error?.response?.data?.errorNum]) {
    processResponseDataWithConfig(messages, error, response)
  }
}

const processResponseDataWithConfig = function (messages, error, response) {
  if (messages[error.response.status][error.response.data.errorNum].message) {
    response.message = messages[error.response.status][error.response.data.errorNum].message
  }
  if (messages[error.response.status][error.response.data.errorNum].type) {
    response.type = messages[error.response.status][error.response.data.errorNum].type
  }
  if (messages[error.response.status][error.response.data.errorNum].ignore) {
    response.ignore = true
  }
  if (messages[error.response.status][error.response.data.errorNum].callback) {
    response.callback = messages[error.response.status][error.response.data.errorNum].callback
  }
}

const handleError = function (error) {
  const textStore = useTextStore()
  const errors = textStore.current.errors
  let message = ''

  if (error.response) {
    if (error.response.status === 401) {
      message = errors.AUTH
      setTimeout(() => { window.location.reload() }, 1000)
    } else if (error.response.status === 403) {
      message = errors.PERMISSION
    } else {
      message = errors.PROCESS
    }
  } else if (error.request) {
    // The request was made but no response was received
    message = errors.COMM
  } else {
    // Something happened in setting up the request that triggered an Error
    message = errors.COMM
  }

  alert.error(message)
}

const handle = {
  handleErrorWith (messages) {
    return error => handleErrorWith(messages, error)
  },
  error (error) {
    handleError(error)
  }
}

export default handle
