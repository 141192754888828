import button from './button'
import calendar from './calendar'
import customer from './customer'
import list from './list'
import pager from './pager'
import status from './status'
import textarea from './textarea'
import timeInput from './timeInput'
import calendarPicker from './calendarPicker'

export default {
  button,
  calendar,
  customer,
  list,
  pager,
  status,
  textarea,
  timeInput,
  calendarPicker
}
