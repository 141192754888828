<script setup>
import { computed, defineEmits, defineProps } from 'vue'
import { useTextStore } from '@/stores/text'
import alert from '@/utils/alert'

const textStore = useTextStore()

const props = defineProps({
  isActive: {
    type: Boolean,
    default: false
  },
  isHeader: {
    type: Boolean,
    default: true
  }
})

const emit = defineEmits(['changeStatus'])

const text = computed(() => textStore.current.awk.button)

const classStatus = computed(() => {
  if (!props.isHeader) {
    return ['is-outlined', props.isActive ? 'is-danger' : 'is-success']
  }
  return [props.isActive ? 'has-text-danger' : 'has-text-success']
})

const iconStatus = computed(() => {
  return props.isActive ? 'fa-solid fa-ban' : 'fa-solid fa-circle-check'
})

const labelStatus = computed(() => {
  return props.isActive ? text.value.btn_disable : text.value.btn_enable
})

const onStatusChange = () => {
  alert.confirm(text.value.alert_change_status_confirm, text.value.confirm.ok, text.value.confirm.cancel, () => {
    emit('statusChange', props.isActive ? 'NOVIG' : 'VIGEN')
  })
}
</script>

<template>
  <BasicButton
    :classButton="classStatus"
    :icon="iconStatus"
    :label="labelStatus"
    @click="onStatusChange"
  />
</template>
