export default {
  title: 'Adjuntos',
  subtitle: 'Agregar adjuntos',
  lbl_name: 'Descripción del adjunto',
  placeholder: 'Ingrese descripción del adjunto aquí',
  lbl_type: 'Tipo del adjunto',
  lbl_public: '¿Público para el cliente?',
  opt_select: 'Seleccione',
  lbl_file: 'Arrastra y suelta tus archivos aquí',
  btn_file: 'Examinar',
  btn_save: 'Guardar',
  tooltip_need_required_files: 'Faltan archivos obligatorios\n',
  tooltip_need_optional_files: 'Faltan archivos opcionales\n',
  tooltip_files_ok: 'No faltan archivos',
  alert_create_attachment_type: 'Debe seleccionar el tipo del documento adjunto para poder continuar',
  alert_create_attachment_file: 'Debe agregar archivos para continuar',
  alert_create_attachment_file_name: 'El nombre de archivo supera el tamaño máximo',
  alert_create_attachment_success: 'El adjunto ha sido creado satisfactoriamente',
  alert_invalid_attachment_extension: 'Extensión de archivo inválida.<br> Se admiten las siguientes extensiones: jpg, jpeg, png, gif, docx, doc, xlsx, xls, pptx, ppt, msg, pdf.',
  alert_fail_upload: 'No fue posible subir todos los archivos, presione botón guardar para subir los archivos faltantes'
}
