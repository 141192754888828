export default {
  todo: {
    endpoint: '/api/rs/v3/todo'
  },
  parameters: {
    endpoint: '/api/rs/v3/parameter'
  },
  customer: {
    endpoint: '/api/rs/v3/customer'
  },
  contact: {
    endpoint: '/api/rs/v3/contact'
  },
  comment: {
    endpoint: '/api/rs/v3/comment'
  },
  tools: {
    endpoint: '/api/rs/v3/tool',
    definition: {
      endpoint: '/api/rs/v3/tool-definition'
    }
  },
  action: {
    endpoint: '/api/rs/v3/action',
    definition: {
      endpoint: '/api/rs/v3/action-definition'
    }
  },
  session: {
    endpoint: '/api/rs/v3/session'
  },
  goal: {
    endpoint: '/api/rs/v3/goal'
  },
  notification: {
    endpoint: '/api/rs/v3/notification',
    interval: {
      success: 30000,
      fail: 300000
    }
  },
  report: {
    endpoint: '/api/rs/v3/report',
    export: {
      endpoint: '/api/rs/v3/export/report'
    }
  },
  company: {
    endpoint: '/api/rs/v3/company'
  },
  users: {
    endpoint: '/api/rs/v3/user'
  },
  application: {
    endpoint: '/api/rs/v3/application'
  },
  profile: {
    endpoint: '/api/rs/v3/profile'
  },
  plan: {
    endpoint: '/api/rs/v3/strategic-plans'
  },
  apiKeyData: {
    endpoint: '/api/rs/v3/apikey'
  },
  ticket: {
    endpoint: '/api/rs/v3/ticket'
  },
  campaign: {
    endpoint: '/api/rs/v3/campaign'
  },
  template: {
    endpoint: '/api/rs/v3/template',
    process: {
      endpoint: '/api/rs/v3/processtemplate'
    },
    definition: {
      endpoint: '/api/rs/v3/templatedefinition'
    }
  },
  history: {
    endpoint: '/api/rs/v3/history'
  },
  load: {
    endpoint: '/api/rs/v3/load'
  },
  powerapp: {
    endpoint: '/api/rs/v3/powerapp'
  },
  process: {
    endpoint: '/api/rs/v3/process'
  },
  survey: {
    endpoint: '/api/rs/v3/survey-definition'
  },
  interaction: {
    endpoint: '/api/rs/v3/interaction'
  },
  user: {
    pairing: {
      endpoint: '/api/rs/v3/userpairing'
    }
  },
  hub: {
    notification: {
      endpoint: '/ws/hub/notification'
    }
  },
  knowledge: {
    endpoint: '/api/rs/v3/knowledge'
  },
  flow: {
    endpoint: '/api/rs/v3/flow'
  },
  businessRule: {
    endpoint: '/api/rs/v3/business-rule'
  },
  layout: {
    endpoint: '/api/rs/v3/layout'
  },
  attachment: {
    endpoint: '/api/rs/v3/attachment'
  },
  instance: {
    endpoint: '/rs/v2/instance'
  },
  message: {
    endpoint: '/api/rs/v3/message',
    definition: {
      endpoint: '/api/rs/v3/message-definition'
    }
  },
  document: {
    endpoint: '/api/rs/v3/document',
    definition: {
      endpoint: '/api/rs/v3/document-definition'
    }
  },
  datalist: {
    endpoint: '/api/rs/v3/datalist'
  },
  replacement: {
    endpoint: '/api/rs/v3/replacement'
  },
  staticContent: {
    path: '/static',
    companyLogo: 'logo_{domain}.png'
  },
  infobipConversation: {
    endpoint: '/api/rs/v3/infobip-conversation'
  },
  apiKey: '2E8BDA5C6BEDD2D229C9265AF5BDD1EF94162908969C9CC674504EF9417DFB543418A54AA74A80A17EB8AC87E64D3717F4777F1552FD248918C382BA33435F02'
}
