export default {
  refresh: {
    pull_to: 'Desliza hacia abajo para refrescar',
    release_to: 'Suelta para refrescar',
    refreshing: 'Refrescando',
    iconArrow: '<i class="fa-solid fa-arrow-down" />',
    iconRefreshing: '<i class="fa-solid fa-arrows-rotate fa-spin" />'
  },
  new_ticket_action: 'Nueva gestión',
  title_filters: 'Por Hacer',
  opt_mine: 'Mis pendientes',
  opt_group: 'Grupo <%= name %>',
  opt_company: 'Empresa',
  display_mode: 'Modo de visualización',
  subtitle_filters: 'Aquí lo tienes, tus pendientes organizados',

  opts_display: [
    { mode: 'list', icon: 'fa-solid fa-list' }
    // { mode: 'calendar', icon: 'fa-solid fa-calendar-days' }
  ],

  showing_ticket_msg: '<span>Pendientes para el identificador <span class="has-text-weight-bold"><%= ticket %></span></span>',
  showing_mine_dates_msg: '<span><span class="has-text-weight-bold">Mis pendientes</span> entre el <%= start %> y el <%= end %></span>',
  showing_dates_msg: '<span>Pendientes <span class="has-text-weight-bold"><%= view %></span> entre el <%= start %> y el <%= end %></span>',

  lbl_filter: 'Filtrar',
  title_search: 'Búsqueda',
  btn_close: 'Cerrar',
  lbl_ticket_search: 'Buscar por identificador',
  placeholder_ticket: 'Ej: 1234567-8',
  or: 'ó',
  lbl_other_search: 'Buscar por otros parámetros',
  opt_all_responsibles: 'Todos los responsables',
  order_by: 'Ordenar por',
  order_options: [
    {
      key: 'FECCRED',
      value: 'Últimos recibidos primero'
    },
    {
      key: 'DESPRID',
      value: 'Prioritarios primero'
    }
  ],
  showing_results: 'Ver <%= quantity %> resultados',
  showing_max: 'Mostrar un máximo de',
  records_options: [
    {
      key: 5,
      value: '5 registros'
    },
    {
      key: 10,
      value: '10 registros'
    },
    {
      key: 25,
      value: '25 registros'
    },
    {
      key: 50,
      value: '50 registros'
    }
  ],
  records: 'registros',

  btn_advanced_filters: 'Filtros avanzados',
  btn_clean_filters: 'Limpiar filtros',
  btn_update: 'Refrescar',
  title_advanced_filters: 'Filtros avanzados',
  title_clean_filters: 'Limpiar filtros',
  title_update: 'Refrescar',
  lbl_plan: 'Plan',
  lbl_process: 'Proceso',
  lbl_task: 'Tarea',
  lbl_state: 'Estado',
  lbl_priority: 'Prioridad',
  lbl_insistence: 'Insistencia',
  btn_apply: 'Aplicar filtros',

  lbl_today: 'Hoy',
  lbl_previous: 'Anteriores',

  opt_select: 'Seleccione',
  opt_all_o: 'Todos',
  opt_all_a: 'Todas',
  opt_none: 'Ninguna',
  opt_without_priority: 'Sin prioridad',

  opt_with_insistence: 'Tiene insistencia',
  opt_without_insistence: 'Sin insistencia',

  opt_ticket_checked: 'Revisado',

  not_found_title: 'No se encontraron resultados',
  not_found_subtitle: 'Intenta modificar o quitar los filtros de búsqueda',

  item: {
    ticket: 'Gestión'
  },

  detail: {
    select_ticket_msg: 'Selecciona un resultado para ver más detalles o ejecutar acciones',
    subject: 'Asunto',
    title: 'Gestión',
    task: 'Tarea',
    process: 'Proceso',
    customer: 'Cliente',
    clasification: 'Clasificación',
    is_parallel_task: '<span class="has-text-weight-bold">Es una tarea paralela</span>',
    parallel_task_done: 'Tareas paralelas terminadas',
    one_parallel_tasks_template: '<%= quantitySons %> Tarea paralela',
    qty_parallel_tasks_template: '<%= quantitySons %> Tareas paralelas',
    about_todo: 'Plazo pendiente',
    about_ticket: 'Plazo gestión',
    dates: '<span class="is-flex is-justify-content-space-between"><span class="todo-date has-text-weight-bold"><%= from %></span> <span class="todo-arrow icon has-text-grey-light"><i class="fas fa-arrow-circle-right"></i></span> <span class="todo-date-right has-text-weight-bold"><%= until %></span></span>',
    status: '<span class="tag is-primary"><%= statusDescription %></span>',
    role: 'Asignado al rol <span class="has-text-weight-bold"><%= roleName %></span>',
    btn_open: 'Abrir ticket',
    btn_dispatch: 'Despachar'
  },

  // view: 'Visualizar',
  // group: 'Grupo',
  // responsible: 'Responsable',
  // ticket: 'Identificador',
  // date: 'Fecha pendientes',
  // plan: 'Plan',
  // process: 'Proceso',
  // task: 'Tarea',
  // priority: 'Prioridad',
  // state: 'Estado',
  // insistence: 'Insistencia',
  // records_per_page: 'Visualización por página',
  // records: 'registros',
  // records_options: {
  //   5: 5,
  //   10: 10,
  //   20: 20
  // },

  // select: 'Seleccione',
  // all_o: 'Todos',
  // all_a: 'Todas',
  // none: 'Ninguna',
  // myToDo: 'Mis pendientes',
  // allCompany: 'Toda la empresa',

  // search: 'Buscar',
  // searching: 'Buscando',

  // title_search: ' ',
  // subtitle_search: 'resultados encontrados para los filtros ingresados',

  // new_ticket_action: 'Crear gestión',
  // new_ticket_msg: 'O presione aquí para crear una nueva gestión',

  // no_results_found: 'No se encontraron pendientes con los filtros seleccionados',

  // item: {
  //   ticket: 'La gestión',
  //   task: 'La tarea',
  //   expired: 'venció',
  //   expires: 'vence',
  //   created: 'fue creada',
  //   assigned: 'fue asignada',
  //   more: 'Ver más',
  //   less: 'Ver menos',
  //   dispatch: 'Despacho rápido',

  //   no_responsible: 'Sin responsable asignado',
  //   no_group: 'Sin grupo asignado',

  //   parallel_task: 'Tarea paralela',

  //   detail: {
  //     title: 'Gestión',

  //     items: {
  //       process: 'Proceso',
  //       task: 'Tarea',
  //       customer: 'Cliente',
  //       clasification: 'Clasificacion',
  //       reference: 'Referencia',
  //       creationDocDate: 'Fecha de creacion gestión',
  //       creationToDoDate: 'Fecha de creacion pendiente',
  //       endDocDate: 'Fecha plazo gestión',
  //       endToDoDate: 'Fecha plazo pendiente',
  //       status: 'Estado de la gestión',
  //       role: 'Rol asignado',
  //       user: 'Usuario asignado',
  //       parallelTasks: 'Tareas paralelas',
  //       isParallelTask: 'Es una tarea paralela',
  //       parallelTaskDone: 'Tareas paralelas terminadas',
  //       oneParallelTasksTemplate: '<%= quantitySons %> Tarea paralela',
  //       qtyParallelTasksTemplate: '<%= quantitySons %> Tareas paralelas'
  //     },

  //     dispatch: 'Despachar'
  //   }
  // },

  dispatch: {
    decision: 'Decisión',
    responsible: 'Responsable',
    compromiseDate: 'Compromiso'
  }
}
