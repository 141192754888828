export default {
  title: 'Transferir gestión',
  subtitle: 'Seleccione Proceso',
  btn_ok: 'Aceptar',
  select: 'Seleccione',

  alert_select_process: 'Debe seleccionar proceso para continuar',
  confirm_replicate_ticket: 'Se guardará el ticket original y se replicará automáticamente',

  confirm_opt: {
    ok: 'Aceptar',
    cancel: 'Cancelar'
  }
}
