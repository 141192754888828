import Vue from 'vue'
import { defineStore } from 'pinia'

export const useLoadingStore = defineStore('loading', {
  state: () => {
    return {
      counter: 0,
      loading: null
    }
  },
  actions: {
    show () {
      this.counter++
      if (this.counter === 1) {
        this.loading = Vue.prototype.$loading.show()
      }
    },
    remove () {
      this.counter--
      if (this.counter === 0 && this.loading) {
        Vue.prototype.$loading.remove(this.loading)
        this.loading = null
      }
    }
  }
})
