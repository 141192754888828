export default {
  title_new_customer: 'Nuevo cliente',
  title_customer_info: 'Datos del Cliente',
  customer_tree: 'Holding cliente',
  customer_without_tree: 'Cliente no posee estructura',
  id: 'RUT',
  direction: 'Dirección',
  postal_code: 'Código Postal',
  id_super_parent: 'Rut Holding',
  business_name_super_parent: 'Razón Social Holding',
  title_contactability_info: 'Información de Contactabilidad',
  information: {
    title: 'Información'
  },
  title_executive_info: 'Cartera Cliente',
  title_tools: 'Herramientas',

  not_selected: 'Ninguno',
  select: 'Seleccione',
  not_asigned: 'Sin asignar',
  opt_no_role: 'Usuario sin rol',
  lbl_customer_type: 'Tipo de Cliente',

  create_customer_invalidrut_alert: 'El rut ingresado no es válido',
  create_customer_success: 'El cliente ha sido creado satisfactoriamente',
  create_customer_requireddata_alert: 'Debe ingresar todos los campos resaltados para continuar',
  create_customer_error_alert: 'El cliente ya existe',
  confirm_close_create_customer: '¿Está seguro que desea cerrar la interfaz de creación? Se perderán los cambios realizados',
  update_customerinfo_success: 'La información del cliente ha sido actualizada satisfactoriamente',
  update_customercontactability_invalid: 'Por favor corregir campos marcados con rojo',
  update_customercontactability_success: 'La información de contacto del cliente ha sido actualizada satisfactoriamente',
  update_customervariable_success: 'La información personalizada del cliente ha sido actualizada satisfactoriamente',
  update_customerexecutive_success: 'La cartera del cliente ha sido actualizada satisfactoriamente',

  confirm: {
    ok: 'Sí',
    cancel: 'No'
  },

  avatar: 'Avatar',
  file: 'Archivo',
  ok: 'Aceptar',
  cancel: 'Cancelar',
  close: 'Cerrar',
  avatar_placeholder: 'Imagen avatar',

  btn_status: 'Cambiar estado',
  status_question: '¿Está seguro que desea cambiar el estado?',
  status_options: {
    enable: 'Activar',
    disable: 'Desactivar'
  },
  update_customerstatus_success: 'El estado del cliente ha sido actualizado satisfactoriamente',
  update_customerstatus_error: 'Ha ocurrido un error al actualizar el estado del cliente',
  alert_customer_permission: 'No tiene permiso para visualizar el cliente'
}
