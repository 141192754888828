<template>
  <div
    ref="dropdown"
    class="autocomplete dropdown"
    :class="[isActive ? 'is-active' : '']"
  >
    <div
      v-if="multiple"
      class="dropdown-trigger is-multiple"
      @click.prevent.stop="toggleFinder"
    >
      <div
        v-for="(internalValueItem, key) in internalValues"
        :key="key"
        class="item"
      >
        <span class="tag is-info is-light">
          {{ internalValueItem | showName(items) }}
          <button
            class="delete is-small"
            @click.stop="onDeselect(internalValueItem)"
          />
        </span>
      </div>
    </div>
    <div
      v-else
      class="dropdown-trigger"
      @click.prevent.stop="toggleFinder"
    >
      <p
        class="control is-expanded has-icons-right"
        :class="{ 'has-icons-left': hasIcons }"
      >
        <input
          class="input"
          :class="[alert ? 'is-danger' : '', disabled ? 'is-cursor-not-allowed' : '']"
          :value="selectedLabel"
          :disabled="disabled"
          readonly
        >
        <span
          v-if="hasIcons"
          class="icon is-small is-left"
        ><i :class="selectedIcon" /></span>
        <span class="icon is-small is-right"><i class="fas fa-search" /></span>
      </p>
    </div>
    <div
      v-if="isActive"
      ref="dropdownDiv"
      class="dropdown-menu"
      :class="{ 'up': openUp }"
    >
      <div class="dropdown-content-search">
        <input
          ref="searchInput"
          v-model="searchText"
          class="input"
          :class="alert ? 'is-danger' : ''"
          :placeholder="validPlaceholder"
          :disabled="disabled"
          @click.prevent.stop
        >
      </div>
      <div
        v-if="multiple && isObject"
        class="dropdown-content"
      >
        <label
          v-for="(availableItem, key) in availableItems"
          :key="key"
          for=""
          class="dropdown-item"
          :class="[selectedItem(key) ? 'is-active': '']"
          @click.prevent.stop="selected(key)"
        >
          {{ availableItem }}
        </label>
      </div>
      <div
        v-else-if="!multiple && isObject"
        class="dropdown-content"
      >
        <label
          v-for="(availableItem, key) in availableItems"
          :key="key"
          for=""
          class="dropdown-item"
          :class="[key === internalValue ? 'is-active': '']"
          @click.prevent.stop="selected(key)"
        >
          {{ availableItem }}
        </label>
      </div>
      <div
        v-else-if="!multiple && isArrayData"
        class="dropdown-content"
      >
        <label
          v-for="(item, key) in availableItems"
          :key="key"
          for=""
          class="dropdown-item"
          :class="[key === internalValue ? 'is-active': '']"
          @click.prevent.stop="selected(key)"
        >

          <span
            v-if="hasIcons"
            class="icon"
          >
            <i :class="item.icon" />
          </span>

          <span>
            {{ item.value }}
          </span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import filter from 'lodash/filter'
import findIndex from 'lodash/findIndex'
import isPlainObject from 'lodash/isPlainObject'
import isArray from 'lodash/isArray'
import pickBy from 'lodash/pickBy'

export default {
  name: 'AutoComplete',
  filters: {
    showName (key, namesMap) {
      return namesMap[key] ? namesMap[key] : key
    }
  },
  components: { },
  model: {
    events: 'input'
  },
  props: {
    value: {
      type: [String, Number, Array],
      default: ''
    },
    items: {
      type: [Object, Array],
      default: () => {
        return {}
      }
    },
    placeholder: {
      type: String,
      default: ''
    },
    multiple: {
      type: Boolean,
      default: false
    },
    alert: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hasIcons: {
      type: Boolean,
      default: false
    },
    returnType: {
      type: String,
      default: 'string'
    }
  },
  data () {
    return {
      internalValue: null,
      internalValues: [],
      searchText: '',
      isActive: false,
      backdrop: null,
      elementContainer: null,
      openUp: false
    }
  },
  computed: {
    isArrayData () {
      return isArray(this.items)
    },
    isObject () {
      return isPlainObject(this.items)
    },
    selectedLabel () {
      let item = null
      if (this.items) {
        if (this.isObject) {
          item = this.items[this.internalValue]
        } else if (this.isArrayData && this.items[this.internalValue]) {
          item = this.items[this.internalValue].value
        }
      }
      return item
    },
    selectedIcon () {
      if (this.isArrayData && this.items) {
        return this.items[this.internalValue]?.icon
      }
      return ''
    },
    availableItems () {
      let availableItems = []
      if (this.items) {
        if (this.isArrayData) {
          availableItems = pickBy(this.items, item => item.value.toUpperCase().indexOf(this.searchText.toUpperCase()) !== -1)
        } else if (this.isObject) {
          availableItems = pickBy(this.items, item => item.toUpperCase().indexOf(this.searchText.toUpperCase()) !== -1)
        }
      }
      return availableItems
    },
    validPlaceholder () {
      return this.placeholder ? this.placeholder : ''
    }
  },
  watch: {
    value () {
      this.processValue()
    },
    internalValue (value) {
      if (this.isArrayData) {
        if (this.items[parseInt(value)]) {
          const key = this.items[parseInt(value)].key
          this.$emit('input', key)
        }
      } else if (this.isObject) {
        this.$emit('input', value)
      }
    },
    internalValues () {
      this.$emit('input', this.internalValues)
    },
    isActive () {
      this.searchText = ''
      this.toggleAutocomplete()
      this.checkDropdownPosition()
    },
    items () {
      this.processValue()
    }
  },
  created () {
    this.processValue()
  },
  destroyed () {
    if (this.backdrop) {
      document.body.removeChild(this.backdrop)
      this.backdrop = null
    }
  },
  mounted () {
    let currentElement = this.$refs.dropdown

    while (currentElement) {
      if (currentElement.localName === 'main' && currentElement.classList.contains('main')) {
        this.elementContainer = currentElement
        break
      }
      currentElement = currentElement.parentNode
    }
  },
  methods: {
    processValue () {
      if (this.multiple) {
        this.internalValues = this.value
      } else {
        if (this.isArrayData) {
          const index = findIndex(this.items, item => {
            return item.key === this.value.toString()
          })

          if (index !== -1) {
            this.internalValue = index.toString()
          }
        } else if (this.isObject) {
          if (this.returnType === 'int') {
            this.internalValue = parseInt(this.value)
          } else {
            this.internalValue = this.value.toString()
          }
        }
      }
    },
    toggleFinder () {
      if (!this.disabled) {
        this.isActive = !this.isActive

        if (this.isActive) {
          this.$nextTick(() => this.$refs.searchInput.focus())
          const callback = e => {
            this.isActive = false
            window.removeEventListener('click', callback)
          }
          window.addEventListener('click', callback)
        }
      }
    },
    selected (item) {
      this.isActive = false
      if (this.multiple) {
        const index = findIndex(this.internalValues, internal => {
          return internal === item
        })

        if (index === -1) {
          this.internalValues.push(item)
          this.internalValues.sort()
        } else {
          this.onDeselect(item)
        }
      } else {
        this.internalValue = item
      }
    },
    onDeselect (value) {
      if (this.multiple) {
        this.internalValues = filter(this.internalValues, val => {
          return val !== value
        })
        this.internalValues.sort()
      }
    },
    selectedItem (key) {
      const index = findIndex(this.internalValues, value => {
        return value === key
      })

      return index !== -1
    },
    toggleAutocomplete () {
      if (this.isActive) {
        this.backdrop = document.createElement('div')
        this.backdrop.addEventListener('click', () => {
          this.isActive = false
        })
        document.body.appendChild(this.backdrop)
      } else if (this.backdrop) {
        document.body.removeChild(this.backdrop)
        this.backdrop = null
      }
    },
    checkDropdownPosition () {
      this.$nextTick(() => {
        const rect = this.$refs.dropdown.getBoundingClientRect()
        const viewportHeight = window.innerHeight || document.documentElement.clientHeight

        if (this.$refs.dropdownDiv) {
          const dropdownHeight = this.$refs.dropdownDiv.offsetHeight
          this.openUp = rect.bottom + dropdownHeight > viewportHeight
        }
      })
    }
  }
}
</script>
